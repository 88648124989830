import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_window_item = _resolveComponent("v-window-item")!

  return (_openBlock(), _createBlock(_component_v_window_item, { value: "kasutajad" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        class: "flex-row justify-center align-center py-4",
        style: {"height":"87px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            onClick: $setup.toggleId,
            class: "me-6",
            height: "100%",
            variant: "outlined"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.toggle ? "Hide Delicate" : "Display Delicate"), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_text_field, {
            modelValue: $setup.searchTerm,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchTerm) = $event)),
            type: "text",
            placeholder: "Search user",
            style: {"max-width":"300px","height":"100%"}
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table, {
        headers: $setup.displayHeaders,
        items: $setup.filteredUsers,
        "hide-default-footer": ""
      }, {
        body: _withCtx(({ items }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: item.id
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayHeaders, (header, index) => {
                return (_openBlock(), _createElementBlock("td", { key: index }, [
                  (header.key === 'role' && $setup.toggle)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _createTextVNode(_toDisplayString(item.role) + " ", 1),
                        _createVNode(_component_v_btn, {
                          small: "",
                          color: 
                    item.role === 'USER' ? 'grey darken-2' : 'purple-darken-1'
                  ,
                          onClick: ($event: any) => ($setup.updateUserRole(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Update Role")
                          ]),
                          _: 2
                        }, 1032, ["color", "onClick"])
                      ]))
                    : (header.key === 'tier')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                          _createTextVNode(_toDisplayString(item.tier) + " ", 1),
                          _createVNode(_component_v_btn, {
                            small: "",
                            color: 
                    item.tier === 'FREE' ? 'grey darken-1' : 'green darken-1'
                  ,
                            onClick: ($event: any) => ($setup.updateUserTier(item))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Update Tier")
                            ]),
                            _: 2
                          }, 1032, ["color", "onClick"])
                        ]))
                      : (header.key === 'lastLogin')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.formatDate(item.lastLogin)), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item[header.key]), 1))
                ]))
              }), 128))
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}