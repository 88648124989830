<template>
  <v-window-item value="kasutajad">
    <v-row
      class="flex-row justify-center align-center py-4"
      style="height: 87px"
    >
      <v-btn @click="toggleId" class="me-6" height="100%" variant="outlined">{{
        toggle ? "Hide Delicate" : "Display Delicate"
      }}</v-btn>
      <v-text-field
        v-model="searchTerm"
        type="text"
        placeholder="Search user"
        style="max-width: 300px; height: 100%"
      />
    </v-row>

    <v-data-table
      :headers="displayHeaders"
      :items="filteredUsers"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tr v-for="item in items" :key="item.id">
          <template v-for="(header, index) in displayHeaders" :key="index">
            <td>
              <span v-if="header.key === 'role' && toggle">
                {{ item.role }}
                <v-btn
                  small
                  :color="
                    item.role === 'USER' ? 'grey darken-2' : 'purple-darken-1'
                  "
                  @click="updateUserRole(item)"
                  >Update Role</v-btn
                >
              </span>
              <span v-else-if="header.key === 'tier'">
                {{ item.tier }}
                <v-btn
                  small
                  :color="
                    item.tier === 'FREE' ? 'grey darken-1' : 'green darken-1'
                  "
                  @click="updateUserTier(item)"
                  >Update Tier</v-btn
                >
              </span>
              <span v-else-if="header.key === 'lastLogin'">
                {{ formatDate(item.lastLogin) }}
              </span>
              <span v-else>
                {{ item[header.key] }}
              </span>
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </v-window-item>
</template>

<script lang="ts">
import { onMounted, ref, computed, Ref } from "vue";
import axios from "axios";

interface User {
  id?: number;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  enabled?: boolean;
  lastLogin?: string;
  tier?: string;
  lastPayment?: string;
  billingDate?: string;
}

export default {
  setup() {
    const toggle: Ref<boolean> = ref(false);
    const searchTerm: Ref<string> = ref("");
    const users: Ref<User[]> = ref([]);

    const headersWithoutId = [
      { title: "First Name", align: "start", key: "firstName" },
      { title: "Last Name", align: "start", key: "lastName" },
      { title: "Email", align: "start", key: "email" },
      { title: "Enabled", align: "start", key: "enabled" },
      { title: "Last Login", align: "start", key: "lastLogin" },
      { title: "Tier", align: "start", key: "tier" },
      { title: "Last Payment", align: "start", key: "lastPayment" },
      { title: "Billing Date", align: "start", key: "billingDate" },
    ];
    const headersWithId = [
      ...headersWithoutId,
      { title: "ID", align: "start", key: "id" },
      { title: "Role", align: "start", key: "role" },
    ];
    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const toggleId = () => {
      toggle.value = !toggle.value;
    };

    const displayHeaders = computed(() =>
      toggle.value ? headersWithId : headersWithoutId,
    );

    const filteredUsers = computed(() => {
      // Add this computed property
      if (!searchTerm.value) {
        return users.value;
      }
      return users.value.filter(
        (user) =>
          user.firstName?.includes(searchTerm.value) ||
          user.lastName?.includes(searchTerm.value),
      );
    });

    const updateUserRole = async (user: User) => {
      try {
        const newRole = user.role === "USER" ? "ADMIN" : "USER";

        const response = await axios.put(`/api/admin/users/${user.id}/role`, {
          role: newRole,
        });
        if (response.status === 200) {
          getUserList();
        }
      } catch (error) {
        console.error(`Updating role for user ${user.id} failed`, error);
      }
    };

    const updateUserTier = async (user: User) => {
      try {
        const newTier = user.tier === "FREE" ? "PAID" : "FREE";

        const response = await axios.put(`/api/admin/users/${user.id}/tier`, {
          tier: newTier,
        });
        if (response.status === 200) {
          user.tier = newTier;
        }
      } catch (error) {
        console.error(`Updating tier for user ${user.id} failed`, error);
      }
    };

    const renderCell = (item: User, column: string): string => {
      if (column == "roleButton") {
        const role = toggle.value ? item.role : "";
        return `${role} <v-btn v-if=${toggle.value} small color="primary" @click=${updateUserRole}>Update Role</v-btn>`;
      } else if (column == "tierButton") {
        return `${item.tier} <v-btn v-if=${toggle.value} small color="primary" @click=${updateUserTier}>Update Tier</v-btn>`;
      } else {
        return (
          (item as unknown as { [key: string]: unknown })[column]?.toString() ||
          ""
        );
      }
    };

    const getUserList = async () => {
      try {
        const response = await axios.get("/api/admin/users/list");
        // Pre-compute the roleButton and tierButton
        users.value = response.data.map((user: User) => ({
          ...user,
          roleButton: user.role, // Create a roleButton
          tierButton: user.tier, // Create a tierButton
        }));
      } catch (error) {
        console.error("Getting list of users failed", error);
      }
    };

    onMounted(() => {
      getUserList();
    });

    return {
      toggle,
      toggleId,
      users,
      searchTerm,
      filteredUsers,
      displayHeaders,
      renderCell,
      updateUserRole,
      updateUserTier,
      formatDate,
    };
  },
};
</script>

<style scoped></style>
