<template>
  <v-app>
    <v-main class="area-2">
      <nav class="area-1">
        <div class="nav-links">
          <router-link to="/">{{ $t("app.navLinks.home") }} | </router-link>
          <router-link v-if="isLoggedIn && isAdmin" to="/dashboard"
            >{{ $t("app.navLinks.dashboard") }} |
          </router-link>
          <router-link v-if="isLoggedIn" to="/courses"
            >{{ $t("app.navLinks.courses") }} |
          </router-link>
          <router-link v-if="isLoggedIn" to="/exercises"
            >{{ $t("app.navLinks.exercises") }} |
          </router-link>
          <router-link to="/about"
            >{{ $t("app.navLinks.about") }} |
          </router-link>
          <router-link v-if="isLoggedIn" to="/account"
            >{{ $t("app.navLinks.account") }} |
          </router-link>
          <router-link v-if="isLoggedIn" to="/" @click="logout">{{
            $t("app.navLinks.logout")
          }}</router-link>
        </div>
        <div class="lang-selector">
          <LanguageSelector />
        </div>
      </nav>
      <div v-if="showButton && isFree && isLoggedIn">
        <router-link to="/account">
          <v-btn
            variant="elevated"
            class="buy-btn text-none mt-6 mb-6"
            color="#4B2D9F"
            >{{ $t("app.subscribeButton") }}</v-btn
          >
        </router-link>
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useAuthStore } from "@/store/authStore";
import { useUserStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import LanguageSelector from "@/components/LanguageSelector.vue";

export default defineComponent({
  name: "App",
  components: { LanguageSelector },

  setup() {
    const store = useAuthStore(); // Initialize your Pinia store
    const userStore = useUserStore();
    const router = useRouter();

    // Use computed property to determine if user is logged in
    const isLoggedIn = computed(() => store.isAuthenticated);
    const isAdmin = computed(() => userStore.role === "ADMIN");
    const isFree = computed(() => userStore.tier === "FREE");

    const logout = () => {
      store.logout();
    };

    const showButton = computed(
      () => router.currentRoute.value.path !== "/account",
    );

    return { isLoggedIn, isAdmin, isFree, logout, userStore, showButton };
  },
});
</script>

<style>
.v-application {
  background-color: transparent !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.buy-btn .v-btn__content {
  color: white !important;
}

nav {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 30px;
}

.nav-links {
  justify-self: center;
}

.lang-selector {
  justify-self: end;
  margin-left: 20px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
